<template>
<div class="area">
  <div class="title">选择地区</div>
  <van-tree-select
  v-model:active-id="activeId"
  v-model:main-active-index="activeIndex"
  :items="items"
  @click-item="selectArea"
/>
</div>
</template>

<script>
import { ref } from 'vue';
import { useStore} from 'vuex';
export default {
  setup(props, { emit }) {
    let store = useStore();
    let pageCityCode = store.state.pageCityCode;
    const activeId = ref(pageCityCode);
    const activeIndex = ref(0);
    
    if (pageCityCode === '451100' || pageCityCode === '450400' || pageCityCode === '450900' || pageCityCode === '450800') {
      activeIndex.value = 1;
    } else if(pageCityCode === '451400' || pageCityCode === '450700' || pageCityCode === '450600' || pageCityCode === '450500'){
      activeIndex.value = 2;
    }else if(pageCityCode === '451000' || pageCityCode === '451200'){
      activeIndex.value = 3;
    }else if(pageCityCode === '450300' || pageCityCode === '450200' || pageCityCode === '451300') {
      activeIndex.value = 4;
    }
    const items = [
      {
        text: '省中心',
        children: [
          { text: '南宁市', id: '450100' },
          { text: '自治区', id: '450000' },
        ],
      },
      {
        text: '桂东',
        children: [
          { text: '贺州市', id: '451100' },
          { text: '梧州市', id: '450400' },
          { text: '玉林市', id: '450900' },
          { text: '贵港市', id: '450800' },
        ],
      },
      {
        text: '桂南',
        children: [
          { text: '崇左市', id: '451400' },
          { text: '钦州市', id: '450700' },
          { text: '防城港市', id: '450600' },
          { text: '北海市', id: '450500' },
        ],
      },
      {
        text: '桂西',
        children: [
          { text: '百色市', id: '451000' },
          { text: '河池市', id: '451200' },
        ],
      },
      {
        text: '桂北',
        children: [
          { text: '桂林市', id: '450300' },
          { text: '柳州市', id: '450200' },
          { text: '来宾市', id: '451300' },
        ],
      },
    ];

    const selectArea = (item) =>{
      console.log(item.text,'选中的地区',item.id);
      store.dispatch('setCityCode',item.id);
      emit('areaCheck', item.text)
    }

    return {
      items,
      activeId,
      activeIndex,
      selectArea
    };
  },
 
};
</script>
<style scoped lang="less">
.area {
  .title {
    text-align: center;
    color: #333;
    padding: 4vw;
  }
}
</style>
<style>
.area .van-sidebar-item--select::before {
  background-color: #ff9900;
}
.area .van-tree-select__item--active{
  color: #ff9900;
}
</style>
<template>
  <div class="home">
    <div class="header flex">
      <div class="area flex" @click="areaPopup">
        <p>{{area}}</p>
        <img src="../assets/img/arrb_w.png" alt="">
      </div>
      <div class="search-box flex">
        <img src="../assets/img/serch.png" @click="searchSubmit">
        <input type="text" placeholder="点击进入信贷产品搜索" v-model="searchVal" @keyup.enter="searchSubmit">
      </div>
      <div class="scan flex">
        <img src="../assets/img/scan.png" @click="scanQR()">
      </div>
    </div>
    <van-popup v-model:show="areaShow" position="right" :style="{ height: '100%' }" closeable
  close-icon="arrow-left" close-icon-position="top-left">
      <div style="width: 70vw">
        <Area @areaCheck="areaCheck" />
      </div>
    </van-popup>
    <div style="height: 12vw"></div>
    <div v-if="!store.state.token" class="login" @click="jumpLogin()">请登录 <van-icon name="arrow" color="#fff" /></div>
    <div v-else class="login" @click="jumpUser()">欢迎您：{{store.state.loginInfo.enterName}} <van-icon name="arrow" color="#fff" /></div>
    <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
      <van-swipe-item>
        <img src="../assets/img/banner1.jpg">
        <img src="../assets/img/cxzs.png" class="img2" @click="goOrgData">
      </van-swipe-item>
      <!-- <van-swipe-item>
        <img src="../assets/img/banner1.jpg">
      </van-swipe-item>
      <van-swipe-item>
        <img src="../assets/img/banner1.jpg">
      </van-swipe-item> -->
  </van-swipe>
    <div class="navs flex-wrap">
      <router-link to="/loan" class="nav flex-col">
        <div class="navimg">
          <img style="height: 8vw" src="../assets/img/icon_dk.png" alt="">
        </div>
        <div class="navtext">我要贷款</div>
      </router-link>
      <router-link to="/myGxm" class="nav flex-col">
        <div class="navimg">
          <img src="../assets/img/icon_gxm.png" alt="">
        </div>
        <div class="navtext">我的桂信码</div>
      </router-link>
      <router-link to="/myCredit" class="nav flex-col">
        <div class="navimg">
          <img src="../assets/img/icon_xy.png" alt="">
        </div>
        <div class="navtext">我的信用</div>
      </router-link>
      <div @click="checkLogin('loanRecord')" class="nav flex-col">
        <div class="navimg">
          <img src="../assets/img/icon_dkjl.png" alt="">
        </div>
        <div class="navtext">我的贷款记录</div>
      </div>
      <div @click="checkLogin('creditAuth')" class="nav flex-col">
        <div class="navimg">
          <img src="../assets/img/icon_sq.png" alt="">
          </div>
        <div class="navtext">我的授权</div>
      </div>
      <router-link to="/creditProduct" class="nav flex-col">
        <div class="navimg">
          <img style="height: 8.2vw" src="../assets/img/icon_zxcp.png" alt="">
          </div>
        <div class="navtext">征信产品中心</div>
      </router-link>
      <router-link to="/fsc" class="nav flex-col">
        <div class="navimg">
          <img src="../assets/img/icon_jrfw.png" alt="">
          </div>
        <div class="navtext">金融服务中心</div>
      </router-link>
      <div class="nav flex-col" @click="test()">
        <div class="navimg">
          <img src="../assets/img/icon_more.png" alt="">
        </div>
        <div class="navtext">更多</div>
      </div>
    </div>
    <div v-if="latestNotice!=''" class="inform flex">
      <div class="tag">最新通知</div><span></span>
      <van-swipe style="height: 8vw; flex:1" vertical :show-indicators="false" :autoplay="5000">
      <van-swipe-item>
        <div class="flex-between">
          <div class="info">{{latestNotice}}</div>
          <router-link to="/latestNoticeContent" class="look">查看</router-link>
        </div>
      </van-swipe-item>
      <!-- <van-swipe-item>
        <div class="flex-between">
          <div class="info">系统明天开始上线</div>
          <router-link to="/newsContent/2" class="look">查看</router-link>
        </div>
      </van-swipe-item> -->
    </van-swipe>
      
    </div>
    <div class="tese-banking">
      <p>特色金融专区</p>
      <div class="tese1">
        <img src="../assets/img/jrheader.png" alt="">
      </div>
      <div class="total-tese2">
      <div class="tese2 flex" ref="scrolls" :style="{width: scrollsWidth}">
        <router-link class="link" to="/countryDevelop">
        <img src="../assets/img/xczx.png" alt="">
        </router-link>
        <router-link class="link" to="/supplyChain">
        <img src="../assets/img/gyljr.png" alt="">
        </router-link>
        <router-link class="link" to="/online">
        <img src="../assets/img/xsjr.png" alt="">
        </router-link>
        <router-link class="link" to="/countryDevelop">
        <img src="../assets/img/xczx.png" alt="">
        </router-link>
      </div>
      </div>
    </div>
    <div class="bank-goods">
      <div class="flex-between">
        <p>最新银行产品</p>
        <router-link to="/loanProduct" class="more">查看更多>></router-link>
      </div>
      
      <div class="flex">
      <van-dropdown-menu class="down-menu" :overlay="false" active-color="#ff9900">
        <van-dropdown-item title="银行" ref="items">
          <div class="down-menu-items">
            <van-checkbox-group v-model="checkLoanBank" direction="horizontal" @click="checkLoanBankChange(checkLoanBank)">
              <van-checkbox name="">不限</van-checkbox>
              <van-checkbox name="GX002">工商银行</van-checkbox>
              <van-checkbox name="GX003">农业银行</van-checkbox>
              <van-checkbox name="GX004">中国银行</van-checkbox>
              <van-checkbox name="GX005">建设银行</van-checkbox>
              <van-checkbox name="GX006">农发银行</van-checkbox>
              <van-checkbox name="GX007">北部湾银行</van-checkbox>
              <van-checkbox name="GX008">农信联社</van-checkbox>
              <van-checkbox name="GX0081">桂林银行</van-checkbox>
              <van-checkbox name="GX009">邮储银行</van-checkbox>
              <van-checkbox name="GX010">交通银行</van-checkbox>
              <van-checkbox name="GX011">柳州银行</van-checkbox>     
              <van-checkbox name="GX012">民生银行</van-checkbox>
              <van-checkbox name="GX013">招商银行</van-checkbox>
              <van-checkbox name="GX014">中信银行</van-checkbox>
              <van-checkbox name="GX015">平安银行</van-checkbox>
              <van-checkbox name="GX016">浦发银行</van-checkbox>
              <van-checkbox name="GX017">进出口银行</van-checkbox>
              <van-checkbox name="GX018">光大银行</van-checkbox>
              <van-checkbox name="GX019">兴业银行</van-checkbox>
              <van-checkbox name="GX020">华夏银行</van-checkbox>
              <van-checkbox name="GX021">国开银行</van-checkbox>
              <van-checkbox name="GX023">汇丰银行</van-checkbox>
              <van-checkbox name="GX025">广发银行</van-checkbox>
              <van-checkbox name="GX026">东亚银行</van-checkbox>
              <van-checkbox name="GX027">渤海银行</van-checkbox>
              <van-checkbox name="GX028">村镇银行</van-checkbox>
            </van-checkbox-group>
            <button class="check_ok" @click="checkConfirm(0)">确定</button>
          </div>          
        </van-dropdown-item>
        <van-dropdown-item title="类型" ref="items2">
          <div class="down-menu-items">
            <van-checkbox-group v-model="checkCreditType" direction="horizontal" @click="checkCreditTypeChange(checkCreditType)">
              <van-checkbox name="">不限</van-checkbox>
              <van-checkbox name="01">贷款</van-checkbox>
              <van-checkbox name="02">票据贴现</van-checkbox>
              <van-checkbox name="03">贸易融资</van-checkbox>
              <van-checkbox name="04">银行承兑汇票</van-checkbox>
              <van-checkbox name="05">信用证</van-checkbox>
              <van-checkbox name="06">保函</van-checkbox>
              <van-checkbox name="07">保理</van-checkbox>
              <van-checkbox name="99">其他</van-checkbox>
            </van-checkbox-group>
            <button class="check_ok" @click="checkConfirm(1)">确定</button>
          </div>
        </van-dropdown-item>
        <van-dropdown-item v-model="moneyValue" :options="money" @change="dropdownMoney()"/>
      </van-dropdown-menu>
      <div class="screen" @click="showPopup">
        <span>全部筛选</span>
        <img src="../assets/img/screen.png" alt="">
        </div>
      </div>      
    </div>
    <van-popup class="sizer" v-model:show="show" round position="top" :style="{ height: '75%' }">
      <Sizer @searchClick="searchClick"/>
    </van-popup>
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >     
    <div class="goods-info" v-for="item in creditProInfo" :key="item.productId">
      <div class="goods" @click="goLoanContent(item.productId,item.loanedCnt,item.bankImgUrl)" >
        <div class="goods-header flex">
          <img :src="store.state.imgURL + item.bankImgUrl">          
          <!-- <span v-else>{{item.orgName}}</span> -->
          <!-- <router-link :to="'/loanProductContent/'+item.productId" class="flex1">{{item.creditName}}</router-link> -->
          <div class="flex1">{{item.creditName}}</div>
          <p class="gray">放款{{item.loanedCnt ? item.loanedCnt : 0}}笔</p>
        </div>
        <div class="goods-content flex">
          <img :src="store.state.imgURL + item.imgUrl" alt="">
          <div class="flex-col">
            <p>融资额度：{{item.minLimitAmt ? item.minLimitAmt+'~'+item.maxLimitAmt + '万': '无限制'}}</p>
            <p>融资金额期限：{{item.minYears ? item.minYears+'~'+item.maxYears + '个月': '无限制'}}</p>
            <p v-if="item.rate">融资利率：{{item.maxRate? item.minRate+'%~'+item.maxRate + '%': item.minRate+'%'}}</p>
            <p v-else>融资利率：{{item.rateOtherContent}}</p>

          </div>
            <div class="star flex">
              <img src="../assets/img/star.png" alt="">
              <img src="../assets/img/star.png" alt="">
              <img src="../assets/img/star.png" alt="">
              <img v-show="parseInt(item.loanedCnt) > 0" src="../assets/img/star.png" alt="">
              <img v-show="parseInt(item.loanedCnt) > 5" src="../assets/img/star.png" alt="">
            </div>
            <div class="share flex" @click.stop="showShareClick()">
              <img src="../assets/img/share.png" alt="">
              <p>分享</p>
            </div>
            <div @click.stop="applyRequirement2(item.productId)" class="apply">我要申请</div>
        </div>
      </div>
    </div>

    </van-list>
<div style="height: 15vw; width:100%"></div>
    <van-share-sheet
  v-model:show="showShare"
  title="立即分享给好友"
  :options="shareOptions"
  @select="onSelect"
  @close="onCloseSelect"
/>


  <van-popup v-model:show="showQR">
    <QrcodeVue :value="showQRurl" :size="256" style="padding: 5px"></QrcodeVue>
    <div class="downImg" @click="downloadAppCodeImg">点击下载二维码</div>
  </van-popup>

    <TabBar v-if="!store.state.isiOS" nav="home"/>
  </div>
</template>

<script>
import TabBar from '../components/TabBar.vue';
import Sizer from '../components/Sizer.vue';
import Area from '../components/Area.vue';
import { Toast,Dialog} from 'vant';
import {useRouter} from 'vue-router';
import { ref,reactive,toRaw,toRefs,onMounted,onUnmounted } from 'vue';
import { useStore} from 'vuex';
import { apiProductList,apiIndexData } from '../api/axios';
import useClipboard from 'vue-clipboard3';
import QrcodeVue from 'qrcode.vue';
export default {
  name: 'Home',
  setup() {
    let router = useRouter();
    let store = useStore();
    if(store.state.isiOS) {
      try {
        window.webkit.messageHandlers.showBar.postMessage(null);
      } catch (e) {
        console.log(e);
      }
    }
    const area = ref('自治区');
    const areaShow = ref(false);
    const areaPopup = () => {
      areaShow.value = true;
    };
    const searchVal = ref('');
    //搜索框键入
    const searchSubmit = () => {
      // console.log('搜索值为：',searchVal.value);
      router.push({
        path: '/loanProduct',
        query: {
          searchVal: searchVal.value
        }
      })
    };
    //跳转统计数据
    const goOrgData = () => {
      // console.log('搜索值为：',searchVal.value);
      router.push({path: '/orgData'})
    };
    const apiProductListInfo = reactive({type: '0',pageNo: 1,provinceBankCode:'',limitAmt:'',creditType:''});
    const items = ref(null);
    const items2 = ref(null);
    const checkLoanBank = ref(['']);
    const checkCreditType = ref(['']);
    const money = reactive([{ text: '金额 ', value: ''},
      { text: '0~50万', value: '0,50'},
      { text: '50~100万', value: '50,100'},
      { text: '100~500万', value: '100,500'},
      { text: '500万以上', value: '500,10000'}] );
    const moneyValue = ref('');
    const dropdownMoney = () =>{
      apiProductListInfo.limitAmt = moneyValue.value;
      reloadApi();
    }
    const show = ref(false);
    const showQR = ref(false);
    const showQRurl = ref('http://yhapp.gaoliuxu.com/#/');
    const showPopup = () => {
      show.value = true;
    };
    const showShare = ref(false);
    const shareOptions = [
      { name: '复制链接', icon: 'link' },
      { name: '二维码', icon: 'qrcode' },
    ];
    const showShareClick = () =>{
      if(store.state.isiOS) {
        try {
          window.webkit.messageHandlers.hideBar.postMessage(null);
        } catch (e) {
          console.log(e);
        }
      } 
      showShare.value = true;
    }
    const onSelect = (option) => {
      // console.log(option.name);
      let urls = window.location.href;
      if(option.name==='复制链接') {
        copy(urls);//复制到剪切板
        showShare.value = false;
      }else {
        showShare.value = false;
        showQRurl.value = urls;
        showQR.value = true;        
      }     
      
    };
    const onCloseSelect = () =>{
      if(store.state.isiOS) {
        try {
          window.webkit.messageHandlers.showBar.postMessage(null);
        } catch (e) {
          console.log(e);
        }
      }      
    }
    const { toClipboard } = useClipboard();
    const copy = async (Msg) => {
      try {
      	//复制
        await toClipboard(Msg);
        Toast('已复制到剪切板');
      } catch (e) {
        Toast('复制失败');
      }
    }

    //获取最新消息通知
    const latestNotice = ref('');
    const getLatestNotice = () =>{
        apiIndexData({}).then(res => {                
             if(res.code === '1'){
                if(res.info.latestNotice) {
                  latestNotice.value = res.info.latestNotice.noticeTheme; 
                }                 
              }
              else {
                Toast(res.msg);
              }
          });  
    }
    getLatestNotice();
    //检查不限
    const checkLoanBankChange = (val) =>{
      if(val[val.length - 1] === '') {
          checkLoanBank.value = [''];
        }else {
          const index = val.findIndex(item => item === '');
          if(index > -1) {
            checkLoanBank.value.splice(index, 1);
          }
        }
    }
    const checkCreditTypeChange = (val) =>{
      if(val[val.length - 1] === '') {
          checkCreditType.value = [''];
        }else {
          const index = val.findIndex(item => item === '');
          if(index > -1) {
            checkCreditType.value.splice(index, 1);
          }
        }
    }
    //产品列表
    const creditProInfo = reactive([]);
    const totalPage = ref(0);
    let sizerVal = {};
    const loading = ref(false);
    const finished = ref(false);
    //筛选确定按钮
    const checkConfirm = (i) => {
      if(!i) {
        apiProductListInfo.provinceBankCode = checkLoanBank.value.toString();
        reloadApi();
        items.value.toggle();
      }else {
        apiProductListInfo.creditType = checkCreditType.value.toString();
        reloadApi();
        items2.value.toggle();
      }      
    };
    function reloadApi() {
        apiProductListInfo.pageNo = 1; //将当前页重置为1        
        totalPage.value = 0; //总页数为空        
        creditProInfo.splice(0,creditProInfo.length);//清空原有的数据        
        onLoad();//重新加载数据
        // finished.value = false;//加载设置为未完成
      }
    function searchClick (searCon) {
      delete apiProductListInfo.provinceBankCode;
      delete apiProductListInfo.creditType;
      delete apiProductListInfo.limitAmt;
      sizerVal = searCon;//保存搜索框的值
      reloadApi(); //重新加载数据 
      show.value = false;//关闭弹窗
      // router.push({
      //    path:'/searchProduct',
      // })
    }
    function areaCheck (areas) {
      areaShow.value = false;
      area.value = areas;
      //重新获取通知
      creditProInfo.splice(0,creditProInfo.length);
      reloadApi();
      getLatestNotice();
    }
    const scrolls = ref(null);
    const scrollsWidth = ref('90vw');
    // 挂载
    onMounted(() => {
      const w = scrolls.value.children.length;
      scrollsWidth.value = w*29.5 + 'vw'
      // console.log(scrollsWidth.value,888)
    });
    //替换城市代码名
    function replaceCityName(str) {
      let cityName= str;
        switch(str) {
            case "450000":
                cityName='自治区';                
                break;
            case "450100":
                cityName='南宁市';                
                break;
            case "450200":
              cityName='柳州市';
              break;
            case "450300":
                cityName='桂林市';
                break;
            case "450400":
              cityName='梧州市';
              break;
            case "450500":
                cityName='北海市';
                break;
            case "450600":
              cityName='防城港市';
              break;
            case "450700":
                cityName='钦州市';
                break;
            case "450800":
              cityName='贵港市';
              break;
            case "450900":
                cityName='玉林市';
                break;
            case "451000":
              cityName='百色市';
              break;
            case "451100":
              cityName='贺州市';
              break;
            case "451200":
                cityName='河池市';
                break;
            case "451300":
              cityName='来宾市';
              break;
            case "451400":
                cityName='崇左市';
                break;
            default:
                // appCity='450000';
                break;
        } 
      area.value = cityName;
    }
    replaceCityName(store.state.pageCityCode);
    function extendJson(target, source) {
            for (var obj in source) {
                target[obj] = source[obj];
            }
            return target;
    }

      const onLoad = () => {
          var loadVal = extendJson(sizerVal,toRaw(apiProductListInfo));
          // var loadVal = toRaw(apiProductListInfo);
          apiProductList(loadVal).then(res => {        
             if(res.code == '1'){
                 res.info.creditProInfo.forEach(e => {
                  creditProInfo.push(e);
                });
                if(apiProductListInfo.pageNo === 1) {
                  totalPage.value = res.info.page.totalPage;
                  finished.value = false;//加载设置为未完成
                }
                // 数据全部加载完成
                if(apiProductListInfo.pageNo >= totalPage.value){
                  finished.value = true;
                }else {
                  apiProductListInfo.pageNo++;
                  // 加载状态结束
                  loading.value = false;
                }
              }
              else {
                Toast(res.msg);
              }
          });        

      };
      // 检查登录
      const checkLogin = (routerName) => {
          if(store.state.token) {
            router.push({name: routerName,params: {page:'back'}})
          }else {
            Dialog.confirm({
              title: '提示！',
              confirmButtonText: '去登录',
              message:
                '您还没有登录！点击下方按钮去登录。',
              })
              .then(() => {
                jumpLogin();
              })
              .catch(() => {
                console.log('取消登录!')
              });
          }
      };
      // 我要申请
      const applyRequirement2 = (id) =>{
        const token = store.state.token;        
        if(token) {          
          router.push({
            path: '/apply',
            query: {
              proIds: id
            }
          });
        }else {
          Dialog.confirm({
            title: '提示！',
            confirmButtonText: '去登录',
            message:
              '您还没有登录！点击下方按钮去登录。',
            })
            .then(() => {
              jumpLogin();
            })
            .catch(() => {
              console.log('取消登录!')
          });
        }
        
      };
      // 扫码方法
      const scanQR = () => {
        if(store.state.isiOS){
          try {
            window.webkit.messageHandlers.scancode.postMessage(null);
          } catch (e) {
            alert("扫码失败");
          }
        }else {
          try {
            window.scanJsAgent.scanBarcode();
          } catch (e) {
            alert("扫码失败");
          }
        }
      };
      //跳转登录页
      const jumpLogin = () => {
        if(store.state.isiOS) {
          try {
            window.webkit.messageHandlers.ocLogin.postMessage(null);
          } catch (e) {
            alert('跳转登录页失败');
          }
        }else {
          router.push({path: '/login'});
        }
      }
      //跳转个人中心
      const jumpUser = () => {
        if(store.state.isiOS) {
          try {
            window.webkit.messageHandlers.gotomyCenter.postMessage(null);
          } catch (e) {
            alert('ios跳转个人中心调用失败');
          }
        }else {
          router.push({path: '/about'});
        }
      }
    //产品详情
      const goLoanContent = (id,cnt,url) =>{
        let obj = {
          productId: id.toString(),
          loanedCnt: cnt,
          bankImgUrl: url
        }
        let obj2 = JSON.stringify(obj);
        // console.log(obj2);
        router.push({
          path: '/loanProductContent',
          query: {
            proIds: encodeURIComponent(obj2)
          }
        });        
      };

      const test = () => {
         console.log('更多')
      }
const downloadAppCodeImg = ()=>{
       let canvas = document.getElementById('app').getElementsByTagName('canvas')
      let a = document.createElement('a')
      a.href = canvas[0].toDataURL('img/png')
      a.download = '二维码.png'
      a.click()
    }
    //卸载
    onUnmounted(()=>{
      if(store.state.isiOS) {
        try {
          window.webkit.messageHandlers.hideBar.postMessage(null);
        } catch (e) {
          console.log(e);
        }
      }
    })
      
      
    return {
      store,
      area,
      areaShow,
      areaPopup,
      areaCheck,
      searchVal,
      searchSubmit,
      goOrgData,
      items,
      items2,
      checkLoanBank,
      checkLoanBankChange,
      checkCreditTypeChange,
      checkConfirm,
      checkCreditType,
      money,
      moneyValue,
      dropdownMoney,
      show,
      showQR,
      showQRurl,
      showPopup,
      shareOptions,
      onSelect,
      onCloseSelect,
      showShare,
      showShareClick,
      searchClick,
      apiProductListInfo,
      creditProInfo,
      onLoad,
      loading,
      finished,
      scrolls,
      scrollsWidth,
      checkLogin,
      applyRequirement2,
      scanQR,
      jumpLogin,
      jumpUser,
      goLoanContent,
      latestNotice,
      downloadAppCodeImg,
      test
    };
  },
  components: {
    TabBar,
    Sizer,
    Area,
    QrcodeVue
  }
}
</script>
<style scoped lang="less">
.home {
  font-size: 4vw;
  background-color: #f5f5f5;
  .my-swipe .van-swipe-item {
    width: 100%;
    height: 40vw;
    position: relative;
    img {
      width: 100%;
      height: 100%
    }
    .img2 {
      position: absolute;
      bottom: 3.5vw;
      left: 6vw;
      width: 26vw;
      height: 7vw;
    }
  }
  .header {
    position: fixed;
    left: 0;
    top: 0;
    background: linear-gradient(to right, #65b8fc, #8193ff);
    padding: 2vw 0;
    width: 100%;
    z-index: 999;
    .area {
      width: 24vw;
      color: #fff;
      justify-content: center;
      img {
        width: 3.2vw;
        margin-left: 2vw;
      }
    }
    
    .scan {
      width: 16vw;
      justify-content: center;
      img {
        width: 7.6vw;
        height: 6.6vw;
      }
    }

  }
  .login {
    text-align: left;
    color: #fff;
    font-size: 4vw;
    background: linear-gradient(to right, #65b8fc, #8193ff);
    padding: 1vw 3vw;
    display: block;
  }
  .navs {
    margin: 3vw 4vw;
    background-color: #fff;
    border-radius: 10px;
    // margin-bottom: 9.4vw;
    .nav {
      width: 25%;
      margin: 4vw 0;
      .navimg {
        width: 100%;
        text-align: center;
        height: 8vw;
        line-height: 8vw;
        img {
          height: 7.6vw;
          width: auto;
        };
      }
      .navtext {
        text-align: center;
        font-size: 3.5vw;
        margin-top: 2vw;
        color: #333;
      }
    }
  }
  .inform {
    margin: 0 4vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 4vw 2vw;
    .tag {
      color: #fff;
      padding: 3px 5px;
      background-color: #69b2fc;
      border-radius: 5px;
    }
    span {
      border-left: 1.6vw solid #69b2fc;
      border-top: 1.6vw solid #fff;
      border-bottom: 1.6vw solid #fff;
      margin-right: 2vw;
    }
    .info {
      display: -webkit-box;
      display: -moz-box;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp:1;
      }
    .look {
      color: #ff9900;
      border-radius: 10vw;
      width: 16vw;
      padding: 3px 0;
      border: 1px solid #ff9900;
      text-align: center;
    }
  }
  .tese-banking {
    margin: 3vw 4vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 3vw 2.2vw;
    p {
      margin: 0;
      padding-bottom: 2vw;
      font-size: 5vw;
      font-weight: 600;
    } 
    .tese1 {
      width: 100%;
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
    .total-tese2 {
      width: 100%;
      overflow-x: auto;
      .tese2 {
      margin-top: 2vw;

      .link{
        width: 28vw;
        margin-right: 1.5vw;
        img{
            width: 100%;
          }
      }
      
    }
    }
    
  }
  .bank-goods {
    margin: 0 4vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 3vw 2.2vw;
    .more {
      color: #666666;
      padding: 0 2vw 2vw;
    }
    p {
      margin: 0;
      padding-bottom: 2vw;
      font-size: 5vw;
      font-weight: 600;
    }
    .down-menu {
      width: 70%;
      .down-menu-items {
        padding: 0 2vw 4vw;
        p {
          font-weight: 600;
          margin-bottom: 1vw;
          font-size: 4vw;
        }
        select {
          background-color: #f5f5f5;
          border: 0;
          width: 100%;
          border: 0;
          padding: 3vw 2vw;
          font-size: 4vw;
          border-radius: 1vw;
          box-sizing: border-box;
       }
      }
    }
    .flex {
      height: var(--van-dropdown-menu-height);
      background-color: var(--van-dropdown-menu-background-color);
      box-shadow: var(--van-dropdown-menu-box-shadow);
      .screen {
      flex: 1;
      height: 48px;
      font-size: 3.5vw;
      text-align: center;
      line-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 3.5vw;
        width: auto;
        margin-left: 1vw;
      }
    }
    }
    
  }

  .goods-info {
    margin: 3vw 4vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 3vw 2.5vw;
    .goods-header {
      margin-bottom: 2vw;
      img,span {
        width: 28.6vw;
        // height: 8vw
      }
      span {
        font-size: 4vw;
        line-height: 8vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .flex1 {
        flex: 1;
        margin-left: 2vw;
        font-size: 4.8vw;
        color: #ff9900;
        font-weight: 600;
      }
      .gray {
        font-size: 3.2vw;
        color: #666666;
      }
    }
    .goods-content {
      position: relative;
      img {
        height: 18.7vw;
        width: 28.6vw;
      }
      .flex-col {
        margin-left: 2vw;
        width: 40vw;
        p {
          margin: 0;
          width: 100%;
          font-size: 3.2vw;
          color: #666666;
          margin: 0.5vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .star {
        position: absolute;
        top: -2vw;
        right: 0;
          img {
            width: 4vw;
            height: 4vw;
            margin-left: 1vw;
          }
      }
      .share {
        position: absolute;
        top: 4vw;
        right: 0;
        border: 1px solid #ff9900;
        padding: 1vw 3vw;
        border-radius: 4vw;
          img {
            width: 3.6vw;
            height: auto;
            margin-right: 1vw;
          }
          p {
            color: #ff9900;
            font-size: 3.2vw;
          }
      }
      .apply {
        position: absolute;
        bottom: 0;
        right: 0;
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 3.5vw;
        padding: 1vw 2vw;
        border-radius: 4vw;
      }

    }

  }
}
</style>
<style lang="less">
.bank-goods .van-dropdown-menu__bar {
    background: none;
    box-shadow: none;
}
.bank-goods .van-dropdown-menu__title {
  font-size: 3.5vw;
}
.bank-goods .van-popup--top {
    left: 6.2vw;
    width: 87.6vw;
}
.bank-goods .van-dropdown-menu__title::after {
  border-width: 4px;
}
.down-menu-items {
      text-align: center;
      .van-radio__icon--checked .van-icon,
        .van-radio__icon .van-icon {
            display: none;
        }
        .van-radio__label {
            background-color: #f5f5f5;
            font-size: 4vw;
            padding: 2vw 2vw;
            border-radius: 1vw;
            margin-top: 2vw;
        }
        .van-radio[aria-checked="true"] .van-radio__label {
            color: #ff9900;
            background-color: #f7dab0; 
        }
        .van-checkbox__icon--checked .van-icon,
        .van-checkbox__icon .van-icon {
            display: none;
        }
        .van-checkbox__label {
            background-color: #f5f5f5;
            font-size: 4vw;
            padding: 2vw 2vw;
            border-radius: 1vw;
            margin-top: 2vw;
        }
        .van-checkbox[aria-checked="true"] .van-checkbox__label {
            color: #ff9900;
            background-color: #f7dab0; 
        }
        .check_ok {
          background: linear-gradient(to right, #ff9900, #ff6700);
          color: #fff;
          font-size: 4vw;
          padding: 1vw 6vw;
          border: 0;
          border-radius: 1vw;
          margin-top: 4vw;
        }
}

</style>
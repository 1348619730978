<template>
    <div class="sizer">
        <div class="bank">
        <p>贷款金额(万)</p>
        <div class="slider">
        <van-slider v-model="value" range :max="200" active-color="#ff9900">
          <template #left-button >
            <div class="slider-button">
              <div class="custom-button"></div>
              <p>{{ value[0]+'万'}}</p>
            </div>            
          </template>
          <template #right-button>
            <div class="slider-button">
              <div class="custom-button"></div>
              <p>{{ value[1]+'万'}}</p>
            </div>  
          </template>
        </van-slider>
        </div>
        </div>
        <div class="bank">
          <p>所属地区</p>
          <van-checkbox-group v-model="cityCode" direction="horizontal" @click="cityCodeChange(cityCode)">
              <van-checkbox name="">不限</van-checkbox>
              <van-checkbox name="450100">南宁市</van-checkbox>
                <van-checkbox name="450200">柳州市</van-checkbox>
                <van-checkbox name="450300">桂林市</van-checkbox>
                <van-checkbox name="450400">梧州市</van-checkbox>
                <van-checkbox name="450500">北海市</van-checkbox>
                <van-checkbox name="450600">防城港市</van-checkbox>
                <van-checkbox name="450700">钦州市</van-checkbox>
                <van-checkbox name="450800">贵港市</van-checkbox>
                <van-checkbox name="450900">玉林市</van-checkbox>
                <van-checkbox name="451000">百色市</van-checkbox>
                <van-checkbox name="451100">贺州市</van-checkbox>
                <van-checkbox name="451200">河池市</van-checkbox>
                <van-checkbox name="451300">来宾市</van-checkbox>
                <van-checkbox name="451400">崇左市</van-checkbox>
          </van-checkbox-group>
        </div>
        <div class="bank">
          <p>产品所属银行</p>
          <van-checkbox-group v-model="provinceBankCode" direction="horizontal" @click="provinceBankCodeChange(provinceBankCode)">
            <van-checkbox name="">不限</van-checkbox>
            <van-checkbox name="GX002">工商银行</van-checkbox>
            <van-checkbox name="GX003">农业银行</van-checkbox>
            <van-checkbox name="GX004">中国银行</van-checkbox>
            <van-checkbox name="GX005">建设银行</van-checkbox>
            <van-checkbox name="GX006">农发银行</van-checkbox>
            <van-checkbox name="GX007">北部湾银行</van-checkbox>
            <van-checkbox name="GX008">农信联社</van-checkbox>
            <van-checkbox name="GX0081">桂林银行</van-checkbox>
            <van-checkbox name="GX009">邮储银行</van-checkbox>
            <van-checkbox name="GX010">交通银行</van-checkbox>
            <van-checkbox name="GX011">柳州银行</van-checkbox>     
            <van-checkbox name="GX012">民生银行</van-checkbox>
            <van-checkbox name="GX013">招商银行</van-checkbox>
            <van-checkbox name="GX014">中信银行</van-checkbox>
            <van-checkbox name="GX015">平安银行</van-checkbox>
            <van-checkbox name="GX016">浦发银行</van-checkbox>
            <van-checkbox name="GX017">进出口银行</van-checkbox>
            <van-checkbox name="GX018">光大银行</van-checkbox>
            <van-checkbox name="GX019">兴业银行</van-checkbox>
            <van-checkbox name="GX020">华夏银行</van-checkbox>
            <van-checkbox name="GX021">国开银行</van-checkbox>
            <van-checkbox name="GX023">汇丰银行</van-checkbox>
            <van-checkbox name="GX025">广发银行</van-checkbox>            
            <van-checkbox name="GX026">东亚银行</van-checkbox>
            <van-checkbox name="GX027">渤海银行</van-checkbox>            
            <van-checkbox name="GX028">村镇银行</van-checkbox>
            </van-checkbox-group>
        </div>
        <div class="inputs">
          <p>产品名称</p>
          <input type="text" placeholder="请输入产品名称" v-model="creditName">
        </div>
        <div class="bank">
        <p>产品类型</p>
        <van-checkbox-group v-model="creditType" direction="horizontal" @click="creditTypeChange(creditType)">
            <van-checkbox name="">不限</van-checkbox>
            <van-checkbox name="01">贷款</van-checkbox>
            <van-checkbox name="02">票据贴现</van-checkbox>
            <van-checkbox name="03">贸易融资</van-checkbox>
            <van-checkbox name="04">银行承兑汇票</van-checkbox>
            <van-checkbox name="05">信用证</van-checkbox>
            <van-checkbox name="06">保函</van-checkbox>
            <van-checkbox name="07">保理</van-checkbox>
            <van-checkbox name="99">其他</van-checkbox>
        </van-checkbox-group>
        </div>
        <div class="bank">
        <p>适用对象</p>
        <van-checkbox-group v-model="userType" direction="horizontal" @click="userTypeChange(userType)">
            <van-checkbox name="">不限</van-checkbox>
            <van-checkbox name="0">微型企业</van-checkbox>
            <van-checkbox name="1">小型企业</van-checkbox>
            <van-checkbox name="2">中型企业</van-checkbox>
            <van-checkbox name="3">大型企业</van-checkbox>
            <van-checkbox name="4">涉农经营企业</van-checkbox>
            <van-checkbox name="5">个体工商户</van-checkbox>
            <van-checkbox name="6">其他</van-checkbox>
        </van-checkbox-group>
        </div>
        <div class="bank">
        <p>担保方式</p>
          <van-checkbox-group v-model="guaType" direction="horizontal" @click="guaTypeChange(guaType)">
              <van-checkbox name="">不限</van-checkbox>
              <van-checkbox name="0">信用</van-checkbox>
              <van-checkbox name="1">抵押</van-checkbox>
              <van-checkbox name="2">保证</van-checkbox>
              <van-checkbox name="3">质押</van-checkbox>
              <van-checkbox name="4">其他</van-checkbox>
          </van-checkbox-group>
        </div>
        <div class="bank">
        <p>是否绿色金融</p>
        <van-radio-group v-model="isGreenFinance" direction="horizontal">
            <van-radio name="">不限</van-radio>
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
        </van-radio-group>
        </div>

        <div class="bottom">
          <!-- <button class="cancel" @click="closePopup(0)">取消</button> -->
          <button class="confirm" @click="closePopup">查询</button>
        </div>
    </div>
    
</template>
<script>
import { ref,reactive,toRefs,toRaw } from 'vue';
import { useStore } from 'vuex';
import { Toast} from 'vant';
export default {
  setup(props,context) {
    let store = useStore();
    const searchValue= reactive({
      cityCode: [''],
      provinceBankCode: [''],
      creditName:'',
      creditType: [''],
      userType: [''],
      guaType: [''],
      isGreenFinance: ''
    })
    // 区间值
    const value = ref([0, 50]);
    // const onChange = (value) => console.log('当前值：' + value);

    const cityCodeChange = (val) =>{
      if(val[val.length - 1] === '') {
          searchValue.cityCode= [''];
        }else {
          const index = val.findIndex(item => item === '');
          if(index > -1) {
            searchValue.cityCode.splice(index, 1);
          }
        }
    }
    const provinceBankCodeChange = (val) =>{
      if(val[val.length - 1] === '') {
          searchValue.provinceBankCode = [''];
        }else {
          const index = val.findIndex(item => item === '');
          if(index > -1) {
            searchValue.provinceBankCode.splice(index, 1);
          }
        }
    }
    const creditTypeChange = (val) =>{
      if(val[val.length - 1] === '') {
          searchValue.creditType = [''];
        }else {
          const index = val.findIndex(item => item === '');
          if(index > -1) {
            searchValue.creditType.splice(index, 1);
          }
        }
    }
    const userTypeChange = (val) =>{
      if(val[val.length - 1] === '') {
          searchValue.userType = [''];
        }else {
          const index = val.findIndex(item => item === '');
          if(index > -1) {
            searchValue.userType.splice(index, 1);
          }
        }
    }
    
    const guaTypeChange = (val) =>{
      if(val[val.length - 1] === '') {
          searchValue.guaType= [''];
        }else {
          const index = val.findIndex(item => item === '');
          if(index > -1) {
            searchValue.guaType.splice(index, 1);
          }
        }
    }
    const closePopup = () => {
    if(searchValue.creditName.length > 50) {
      Toast('产品名称过长');
      return;
    }
    // 传参到父组件
    let transVal = {};
    transVal.limitAmt = value.value.toString();
    transVal.cityCode = searchValue.cityCode.toString();
    transVal.provinceBankCode = searchValue.provinceBankCode.toString();
    transVal.creditType = searchValue.creditType.toString();
    transVal.userType = searchValue.userType.toString();
    transVal.guaType = searchValue.guaType.toString();
    transVal.creditName = searchValue.creditName;
    transVal.isGreenFinance = searchValue.isGreenFinance;
    
    // console.log(transVal,995263)
    context.emit('searchClick',transVal);
    };
    return {
      value,
      store,
      cityCodeChange,
      provinceBankCodeChange,
      creditTypeChange,
      userTypeChange,
      guaTypeChange,
      closePopup,
      ...toRefs(searchValue)
    };
  },
};
</script>
<style lang="less">
.sizer {
    .bank {
        p {
            font-weight: 600;
            font-size: 4vw;
        }
        .slider{
          padding: 10vw 2vw 0 2vw;
        .slider-button {
          position: relative;   
          .custom-button {
            
            width: 6vw;
            height: 6vw;
            text-align: center;
            background-color: #ff9900;
            border-radius: 50%;           
            
          }
          p {
              width: 11vw;
              text-align: center;
              color: #ff9900;
              font-size: 4vw;
              position: absolute;
              top: -7vw;
              left: -2.5vw;
            }
          }
        }
        margin: 4vw;
        .van-radio__icon--checked .van-icon,
        .van-radio__icon .van-icon {
            display: none;
        }
        .van-radio__label {
            background-color: #f5f5f5;
            font-size: 4vw;
            padding: 2vw 2vw;
            border-radius: 1vw;
            margin-top: 2vw;
        }
        .van-radio[aria-checked="true"] .van-radio__label {
            color: #ff9900;
            background-color: #f7dab0; 
        }
        .van-checkbox__icon--checked .van-icon,
        .van-checkbox__icon .van-icon {
            display: none;
        }
        .van-checkbox__label {
            background-color: #f5f5f5;
            font-size: 4vw;
            padding: 2vw 2vw;
            border-radius: 1vw;
            margin-top: 2vw;
        }
        .van-checkbox[aria-checked="true"] .van-checkbox__label {
            color: #ff9900;
            background-color: #f7dab0; 
        }
        .check_ok {
          background: linear-gradient(to right, #ff9900, #ff6700);
          color: #fff;
          font-size: 4vw;
          padding: 1vw 4vw;
          border: 0;
          border-radius: 4vw;
          margin-top: 4vw;
        }
    }
    .inputs{
      margin: 4vw;
      p {
        font-weight: 600;
        margin-bottom: 2.5vw;
      }
      select,
      input {
          background-color: #f5f5f5;
          border: 0;
          width: 100%;
          border: 0;
          padding: 3vw 2vw;
          font-size: 4vw;
          border-radius: 1vw;
          box-sizing: border-box;
       }
    }
    .bottom {
      padding: 4vw 0;
      display: flex;
      justify-content: center;
      button.confirm {
        padding: 2vw 8vw;
        border: 0;
        border-radius: 5vw;
        font-weight: 600;
        font-size: 5vw;
        color: #fff;
        background: linear-gradient(to right, #ff9900, #ff6700);
      }

    }
}
</style>
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { Toast,Dialog,Badge,Swipe, SwipeItem,DropdownMenu, DropdownItem,Slider,RadioGroup, Radio,Popup,Icon,Tab,Tabs,Collapse, CollapseItem,ShareSheet,Checkbox, CheckboxGroup,List,TreeSelect, CountDown } from 'vant';
import store from './store';
const app = createApp(App).use(store);
app.use(Badge);
app.use(Swipe);
app.use(SwipeItem);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Slider);
app.use(Radio);
app.use(RadioGroup);
app.use(Popup);
app.use(Icon);
app.use(Tab);
app.use(Tabs);
app.use(Collapse);
app.use(CollapseItem);
app.use(ShareSheet);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(List);
app.use(CountDown);
app.use(Toast);
app.use(TreeSelect);
app.use(Dialog);

app.use(router);
app.mount('#app')
import { createStore } from 'vuex'

export default createStore({
  state: {
    isiOS: false,
    loginInfo: {},
    token: '',
    pageCityCode: '450000',
    imgURL: 'https://gxr.dsjfzj.gxzf.gov.cn/',
    imgURL2: 'https://gxr.dsjfzj.gxzf.gov.cn/',
    imgURLOrgApp: 'http://gxr.dsjfzj.gxzf.gov.cn:28083/gxfinan_org_app',
    msg: 0,
    auth: false,
    authStep: 1,
    requireInfo: {},
    saveFrontRequirement: {},
    contrastList: [],//产品对比列表
    // realNameAuthLink: {step: 1}
  },
  // 修改变量（state不能直接赋值修改，只能通过mutations）
  mutations: {
    getISiOS(state, newValue){
      state.isiOS = newValue;
    },
    getCollect(state, newValue){
      for(let i=0; i< state.data.length; i++) {
        if(state.data[i].id == newValue) {
          state.data[i].collect = !state.data[i].collect;
          break;
        }
      }
    },
    getAuth(state, newValue){
      state.auth = newValue.auth;
      state.authStep = newValue.step;
    },
    getToken(state, newValue){
      state.loginInfo = newValue;
      state.token = newValue.token;
    },
    getRequireInfo(state, newValue){
      state.requireInfo = newValue;
    },
    getRequestData(state, newValue){
      state.saveFrontRequirement = newValue;
    },
    getCityCode(state, newValue){
      state.pageCityCode = newValue;
    },
    getContrastList(state, newValue){
      state.contrastList.push(newValue);
    },
    getSpliceContrastList(state, newValue){
      state.contrastList.splice(newValue,1);
    },
    getMsg(state, newValue){
      let nArr = newValue.loanList.concat(newValue.policyList).concat(newValue.productRecomList);
      let nMsg = 0;
      nArr.forEach(item => {
        if(item.readStatus!='1') {
          nMsg ++;
        }
      });
      state.msg =nMsg;     
    },

  },
  // mutations的值由actions传入
  actions: {
    // 参数一：自带属性，参数二：新值
    setISiOS(context, value){
      context.commit('getISiOS',value)
    },
    setCollect(context, value){
      context.commit('getCollect',value)
    },
    setAuth(context, value){
      context.commit('getAuth',value)
    },
    setToken(context, value){
      context.commit('getToken',value)
    },
    setRequireInfo(context, value){
      context.commit('getRequireInfo',value)
    },
    setRequestData(context, value){
      context.commit('getRequestData',value)
    },
    setCityCode(context, value){
      context.commit('getCityCode',value)
    },
    setContrastList(context,value){
      context.commit('getContrastList',value)
    },
    setSpliceContrastList(context,value){
      context.commit('getSpliceContrastList',value)
    },
    setMsg(context,value){
      context.commit('getMsg',value)
    },
  },
  modules: {
  }
})

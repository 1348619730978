import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store/index';;

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'User',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/User.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/message.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/news/:type',
    name: 'news',
    component: () => import('../views/new/news.vue')
  },
  {
    path: '/newsContent/:id',
    name: 'newsContent',
    component: () => import('../views/new/NewsContent.vue')
  },
  {
    path: '/policyContent/:id',
    name: 'policyContent',
    component: () => import('../views/new/PolicyContent.vue')
  },
  {
    path: '/knowledgeContent/:id',
    name: 'knowledgeContent',
    component: () => import('../views/new/KnowledgeContent.vue')
  },
  {
    path: '/noticeContent/:id',
    name: 'noticeContent',
    component: () => import('../views/new/NoticeContent.vue')
  },
  {
    path: '/latestNoticeContent',
    name: 'latestNoticeContent',
    component: () => import('../views/new/LatestNoticeContent.vue')
  },
  {
    path: '/messageContent/:id',
    name: 'messageContent',
    component: () => import('../views/MessageContent.vue')
  },
  {
    path: '/searchProduct',
    name: 'searchProduct',
    component: () => import('../views/SearchProduct.vue')
  },
  {
    path: '/gxm',
    name: 'gxm',
    component: () => import('../views/Gxm.vue')
  },
  {
    path: '/loan',
    name: 'loan',
    component: () => import('../views/Loan.vue')
  },
  {
    path: '/fsc',
    name: 'fsc',
    component: () => import('../views/FSC.vue')
  },
  {
    path: '/frame',
    name: 'frame',
    component: () => import('../views/Frame.vue')
  },
  {
    path: '/loanRecord',
    name: 'loanRecord',
    component: () => import('../views/LoanRecord.vue')
  },
  {
    path: '/loanProgress',
    name: 'loanProgress',
    component: () => import('../views/LoanProgress.vue')
  },
  {
    path: '/loanProgressContent',
    name: 'loanProgressContent',
    component: () => import('../views/LoanProgressContent.vue')
  },
  {
    path: '/loanDemandContent',
    name: 'loanDemandContent',
    component: () => import('../views/LoanDemandContent.vue')
  },
  {
    path: '/systemIntroduction',
    name: 'systemIntroduction',
    component: () => import('../views/system/SystemIntroduction.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/system/Faq.vue')
  },
  {
    path: '/collect',
    name: 'collect',
    component: () => import('../views/system/Collect.vue')
  },
  {
    path: '/companyInfo',
    name: 'companyInfo',
    component: () => import('../views/system/CompanyInfo.vue')
  },
  {
    path: '/editCompanyInfo',
    name: 'editCompanyInfo',
    component: () => import('../views/system/EditCompanyInfo.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/system/ContactUs.vue')
  },
  {
    path: '/openAccount',
    name: 'openAccount',
    component: () => import('../views/system/OpenAccount.vue')
  },
  {
    path: '/creditAuth',
    name: 'creditAuth',
    component: () => import('../views/system/CreditAuth.vue')
  },
  {
    path: '/creditAuthEdit',
    name: 'creditAuthEdit',
    component: () => import('../views/system/CreditAuthEdit.vue')
  },
  {
    path: '/newAuth',
    name: 'newAuth',
    component: () => import('../views/system/NewAuth.vue')
  },
  {
    path: '/passwordChange',
    name: 'passwordChange',
    component: () => import('../views/system/PasswordChange.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/system/Login.vue')
  },
  {
    path: '/logins',
    name: 'logins',
    component: () => import('../views/system/Logins.vue')
  },
  {
    path: '/passwordForget',
    name: 'passwordForget',
    component: () => import('../views/system/PasswordForget.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/system/Chat.vue')
  },
  {
    path: '/chatView',
    name: 'chatView',
    component: () => import('../views/system/ChatView.vue')
  },
  {
    path: '/evalOrg',
    name: 'evalOrg',
    component: () => import('../views/system/EvalOrg.vue')
  },
  {
    path: '/stopLoan',
    name: 'stopLoan',
    component: () => import('../views/system/StopLoan.vue')
  },
  {
    path: '/orgData',
    name: 'orgData',
    component: () => import('../views/system/OrgData.vue')
  },
  {
    path: '/loanProduct',
    name: 'loanProduct',
    component: () => import('../views/product/LoanProduct.vue')
  },
  {
    path: '/loanProductContent',
    name: 'loanProductContent',
    component: () => import('../views/product/LoanProductContent.vue')
  },
  {
    path: '/productContrast',
    name: 'productContrast',
    component: () => import('../views/product/ProductContrast.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('../views/product/Apply.vue')
  },
  {
    path: '/apply2',
    name: 'apply2',
    component: () => import('../views/product/Apply2.vue')
  },
  {
    path: '/applyB',
    name: 'applyB',
    component: () => import('../views/product/ApplyB.vue')
  },
  {
    path: '/applyC',
    name: 'applyC',
    component: () => import('../views/product/ApplyC.vue')
  },
  {
    path: '/applyD',
    name: 'applyD',
    component: () => import('../views/product/ApplyD.vue')
  },
  {
    path: '/creditProduct',
    name: 'creditProduct',
    component: () => import('../views/product/CreditProduct.vue')
  },
  {
    path: '/countryDevelop',
    name: 'countryDevelop',
    component: () => import('../views/banking/CountryDevelop.vue')
  },
  {
    path: '/supplyChain',
    name: 'supplyChain',
    component: () => import('../views/banking/SupplyChain.vue')
  },
  {
    path: '/supplyChainApply',
    name: 'supplyChainApply',
    component: () => import('../views/banking/SupplyChainApply.vue')
  },
  {
    path: '/online',
    name: 'online',
    component: () => import('../views/banking/Online.vue')
  },
  {
    path: '/myCredit',
    name: 'myCredit',
    component: () => import('../views/banking/MyCredit.vue')
  },
  {
    path: '/myGxm',
    name: 'myGxm',
    component: () => import('../views/banking/MyGxm.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/auth/Auth.vue')
  },
  {
    path: '/realNameAuth',
    name: 'realNameAuth',
    component: () => import('../views/auth/RealNameAuth.vue')
  },
  {
    path: '/realNameAuthB',
    name: 'realNameAuthB',
    component: () => import('../views/auth/RealNameAuthB.vue')
  },
  {
    path: '/realNameAuthC',
    name: 'realNameAuthC',
    component: () => import('../views/auth/RealNameAuthC.vue')
  },
  {
    path: '/realNameAuthD',
    name: 'realNameAuthD',
    component: () => import('../views/auth/RealNameAuthD.vue')
  },
  {
    path: '/authInfo',
    name: 'authInfo',
    component: () => import('../views/auth/AuthInfo.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  // 兼容IE
  window.scrollTo(0, 0);
  next();
})
export default router

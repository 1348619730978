<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->

  <router-view v-if="isLoad" />

</template>
<script>
import { useStore} from 'vuex';
import { apiGetDatas } from './api/axios';
import { ref,reactive,toRaw } from 'vue';
export default {  
  setup() {
    let store = useStore();
    const isLoad = ref(false);
    const state = reactive({
        loanList: [],
        policyList: [],
        productRecomList: []
    });

    window.setCity = androidSetCity;
    window.ocBackToken = iosToken;
    let u = navigator.userAgent;
    // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端    
    if(isiOS) {
      store.dispatch('setISiOS',true);
      try {
        window.webkit.messageHandlers.getToken.postMessage(null);
      } catch (e) {
        console.log('ios获取登录信息调用失败');
        isLoad.value = true;
      }
    }else {
      // console.log('非ios系统');
      isLoad.value = true;
      try {
        window.scanJsAgent.showLocationDialog();
      } catch (e) {
        console.log('android手机定位调用失败');
      };
      if(localStorage.getItem("gxrToken")){
        store.dispatch('setToken',JSON.parse(localStorage.getItem("gxrToken")));
      };
    }

    function androidSetCity(str1,str2) {
      if(str2=='自治区'){
        return;
      }else{
        let appCity= conCityCode(str2);
        store.dispatch('setCityCode',appCity);
      }
    }

    function conCityCode(str){
      let appCity= '450000';
      switch(str) {
            case "南宁市":
                appCity='450100';                
                break;
            case "柳州市":
              appCity='450200';
              break;
            case "桂林市":
                appCity='450300';
                break;
            case "梧州市":
              appCity='450400';
              break;
            case "北海市":
                appCity='450500';
                break;
            case "防城港市":
              appCity='450600';
              break;
            case "钦州市":
                appCity='450700';
                break;
            case "贵港市":
              appCity='450800';
              break;
            case "玉林市":
                appCity='450900';
                break;
            case "百色市":
              appCity='451000';
              break;
            case "贺州市":
              appCity='451100';
              break;
            case "河池市":
                appCity='451200';
                break;
            case "来宾市":
              appCity='451300';
              break;
            case "崇左市":
              appCity='451400';
              break;
          }
      return appCity;
    }
    async function iosToken(params) {
      if(params) {
        await store.dispatch('setToken',params);        
        isLoad.value = true;
      }else {
        isLoad.value = true;
        return;
      }
    }
    
    // console.log(localStorage.getItem("gxrMessage"))
    let loacalMessage = JSON.parse(localStorage.getItem("gxrMessage"));
    if(loacalMessage) {
        apiGetDatas({}).then(res => {
        // console.log(res,'app--message first返回的数据have lo')                  
        if(res.code == '1') {
          res.info.loanList.forEach((item) => {
              var filter = loacalMessage.loanList.filter(function (item2) {
                  return item.id == item2.id
              });
              if(filter.length) {
                  state.loanList.push(filter[0]);
              }else {
                  state.loanList.push(item);
              }
           })
          //  state.loanList = res.info.loanList;
           res.info.policyList.forEach((item) => {
              var filter = loacalMessage.policyList.filter(function (item2) {
                  return item.id == item2.id
              });
              if(filter.length) {
                  state.policyList.push(filter[0]);
              }else {
                  state.policyList.push(item);
              }
           })
          //  state.productRecomList = res.info.productRecomList;
          res.info.productRecomList.forEach((item) => {
              var filter = loacalMessage.productRecomList.filter(function (item2) {
                  return item.id == item2.id
              });
              if(filter.length) {
                  state.productRecomList.push(filter[0]);
              }else {
                  state.productRecomList.push(item);
              }
           })

           localStorage.setItem("gxrMessage", JSON.stringify(toRaw(state)));
           store.dispatch('setMsg',toRaw(state));           
         }
         else {
           console.log(res.msg);
         }
        });

    }else{
      apiGetDatas({}).then(res => {
        // console.log(res,'app--message first返回的数据')                   
        if(res.code == '1') {
            localStorage.setItem("gxrMessage", JSON.stringify(res.info));
            store.dispatch('setMsg',res.info);
          }
          else {
            console.log(res.msg);
          }
      });
    };

    return {
      isLoad
    }
  },

}
</script>
<style lang="less">
body {
  margin: 0;
  background-color: #f5f5f5;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: PingFangSC-Regular, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #333;
  
}
p {
  margin: 0;
}
input {
  border: 0;
  outline: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
}
select{
   /*取消默认箭头*/
   appearance:none;
   -moz-appearance:none;
   -webkit-appearance:none;
}
.select {
  position: relative;
}
.select:after {
    position: absolute;
    /* pointer-events设置为none，使元素不会成为鼠标事件的目标，让鼠标事件可以指向后代元素 */
    pointer-events: none; 
    right: 3vw;
    top: 30%;
    width: 2.5vw;
    height: 2.5vw;
    content: '';
    border: 1px solid #999;
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
}



.navTitle {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 1001;
    background: linear-gradient(to right, #65b8fc, #8193ff);
    height: 12.5vw;
    text-align: center;
    line-height: 12.5vw;
    color: #fff;
    font-size: 4vw;
    img {
        height: 4vw;
        position: absolute;
        left: 0;
        top: 0.3vw;
        padding: 4vw;
    }
}
.navHeader {
    background: linear-gradient(to right, #65b8fc, #8193ff);
    padding: 0 4vw 2vw;
}
.search-box {
   flex: 1;
   background-color: rgba(255,255,255, .3);
  //  padding: 2vw;
   border-radius: 5px;
   img {
     width: 4vw;
     height: 4vw;
     margin: 2vw;
   }
   input {
     width: 100%;
    //  height: 4vw;
     padding: 0;
     margin: 0;
     font-size: 4vw;
    //  line-height: 4vw;
     background-color: transparent;
     color: #fff;
   }
   input::-webkit-input-placeholder {color: #fff;}
}
.card {
    margin: 4vw;
    background-color: #fff;
    border-radius: 1.5vw;
    padding: 4vw;
}
.overflow1 {
    display: -webkit-box;
    display: -moz-box;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;   /*显示行数*/
}
.overflow2 {
    display: -webkit-box;
    display: -moz-box;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:2;   /*显示行数*/
}
.flex {
  display: flex;
  align-items: center;
  /*safari*/
  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  -webkit-box-align: center;
  -webkit-align-items: center;
  // -webkit-flex-direction: column;
  // -webkit-justify-content: center;
}
.flex-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*safari*/
  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  -webkit-box-align: center;
  -webkit-align-items: center;
  // -webkit-flex-direction: column;
  -webkit-justify-content: space-between;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
//倒计时读秒
.verify2 .van-count-down {
  color: #ff9700;
  font-size: 4vw;
}
.card-html {
  max-width: 100% !important;
  overflow: hidden;
}
.card-html img {
  max-width: 100% !important;
  // object-fit: contain;
  height: auto !important;
}
.downImg {
  width:100%;
  text-align: center;
  font-size: 4vw;
  padding: 2vw 0;
}
</style>

/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
 import axios from 'axios';
 import { Toast,Dialog } from 'vant';
 import store from '../store/index';
 import router from '../router/index';

 //环境的切换
//  if (process.env.NODE_ENV == 'development') {    
//      axios.defaults.baseURL = 'http://183.62.50.114:18999';
//  } else if (process.env.NODE_ENV == 'debug') {    
//      axios.defaults.baseURL = 'http://183.62.50.114:18999';
//  } else if (process.env.NODE_ENV == 'production') {    
//      axios.defaults.baseURL = 'http://gxr.dsjfzj.gxzf.gov.cn:28083';
//  }
 axios.defaults.baseURL = 'http://183.62.50.114:18999';
 // 请求超时时间
 axios.defaults.timeout = 10000;
 // post请求头
 axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
 
 // 请求拦截器
 axios.interceptors.request.use(    
     config => { 
        // 每次发送请求之前判断vuex中是否存在token        
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
        // const token = store.state.token;        
        // token && (config.headers.Authorization = token);
        // if (config.method === 'post') {
        //     config.headers['Content-Type']="application/json";
        //     config.data = JSON.stringify(config.data)
        // } 

        return config;  
    },
    error => {        
      return Promise.error(error);  
    })
 
 // 响应拦截器
 axios.interceptors.response.use(    
     response => {        
        // console.log(response,'响应拦截器')
        if(response.data.msg !== undefined && response.data.msg.indexOf("token") != -1) {
            Dialog.alert({
              message:
                '未登录或登录过期,请重新登录。',
              })
            .then(() => {
              localStorage.clear();
              store.dispatch('setToken',{token:''});
              router.replace({                        
                name: 'home',                        
                // query: { 
                //     redirect: router.currentRoute.fullPath 
                // }
              });
            });
            
            return Promise.reject(response);
        }else{
          
          return Promise.resolve(response);
        }        
     },
     error => {       
         return Promise.reject(error);       
     }
 );
//  export default axios
 /** 
  * get方法，对应get请求 
  * @param {String} url [请求的url地址] 
  * @param {Object} params [请求时携带的参数] 
  */
 function get(url, params){    
     return new Promise((resolve, reject) =>{        
         axios.get(url, {            
             params: params        
         })        
         .then(res => {            
             resolve(res.data);        
         })        
         .catch(err => {            
             reject(err.data)        
         })    
     });
 }
 /*删除json对象空值*/
 function deleteEmptyProperty(object){
    for (var i in object) {
      var value = object[i];
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          if (value.length == 0) {
            delete object[i];
            continue;
          }
        }
        this.deleteEmptyProperty(value);
        if (this.isEmpty(value)) {
          delete object[i];
        }
      } else {
        if (value === '' || value === null || value === undefined) {
          delete object[i];
        } else {
        }
      }
    }
  };
 /** 
  * post方法，对应post请求 
  * @param {String} url [请求的url地址] 
  * @param {Object} params [请求时携带的参数] 
  */
  function post(url, params) {
    const token = store.state.token;        
    token && (params.token = token);//统一加上token
    // params.token = store.state.token; //统一加上token
    // console.log(params,'----post提交的参数')
    return new Promise((resolve, reject) => {         
        axios.post(url,params)        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}
 function post1(url, params) {
    const token = store.state.token;        
    token && (params.token = token);//统一加上token
     params.pageCityCode = store.state.pageCityCode; //统一加上pageCityCode
     deleteEmptyProperty(params);//去掉空字符
     
    //  console.log(params,'----post提交的参数')
     return new Promise((resolve, reject) => {         
         axios.post(url,params)        
         .then(res => {            
             resolve(res.data);        
         })        
         .catch(err => {            
             reject(err.data)
         })    
     });
 }

function postNoParams(url) {  
  return new Promise((resolve, reject) => {         
      axios.post(url)        
      .then(res => {            
          resolve(res.data);        
      })        
      .catch(err => {            
          reject(err.data)        
      })    
  });
}


  /** 
  * api统一接口管理 
  * @param {Object} h [请求时携带的参数] 
  */
  /*用户登录*/
  export const apiLogin = h => post('/gxfinan_org_app/login', h);
  /*进入注册页面*/
  export const apiGoRegister = () => postNoParams('/gxfinan_org_app/register/goRegister');
  /*发送验证码*/
  export const apiSendVerificationCode = h => post('/gxfinan_org_app/register/sendVerificationCode', h);
  /*提交注册*/
  export const apiSaveRegisterInfo = h => post('/gxfinan_org_app/register/saveRegisterInfo', h);
  /*银行信贷产品首页（菜单链接）+ 查询*/
  export const apiProductList = h => post1('/gxfinan_org_app/appGxCreditMng/front/productList', h);
  /*银行信贷产品详情*/
  export const apiProductDetail = h => post('/gxfinan_org_app/appGxCreditMng/front/getProductDetail', h);
  /*比较产品*/
  export const apiProCompare = h => post('/gxfinan_org_app/appGxCreditMng/front/proCompare', h);
  /*获取银行信贷产品列表 申请需求+申请贷款*/
  export const apiApplyRequirement = h => post1('/gxfinan_org_app/appGxCreditMng/front/applyRequirement', h);
  /*提交申请*/
  export const apiSaveFrontRequirementOK = h => post('/gxfinan_org_app/appGxCreditMng/saveFrontRequirementOK', h);
  /*指定银行申请贷款-发布申请*/
  export const apiEditRequirement = h => post('/gxfinan_org_app/appGxCreditMng/editRequirement', h);
  /*指定银行申请贷款-提交申请*/
  export const apiSaveRequirementOK = h => post('/gxfinan_org_app/appGxCreditMng/saveRequirementOK', h);
  /*收藏信贷产品*/
  export const apiCollect = h => post('/gxfinan_org_app/appGxCreditMng/collect', h); 
  /*动态获取区县信息*/
  export const apiGetDistrict = h => post('/gxfinan_org_app/asynch/getDistrict', h);
  /*动态获取银行分行信息*/
  export const apiBranchBanks = h => post('/gxfinan_org_app/asynch/getBranchBanks', h);
  /*动态获取银行支行信息*/
  export const apiSubBranchBanks = h => post('/gxfinan_org_app/asynch/getSubBranchBanks', h);
  /*5.1我的贷款申请（列表）*/
  export const apiRequireList = h => post('/gxfinan_org_app/gxApplyProgress/requireList', h);
  /*5.2申请进度（列表）*/
  export const apiApprovalList = h => post('/gxfinan_org_app/gxApplyProgress/approvalList', h);
  /*5.2.1查看进度-进度详情*/
  export const apiShowApprovalDetail = h => post('/gxfinan_org_app/gxApplyProgress/showApprovalDetail', h);
  /*5.2.2查看进度-终止流程*/
  export const apiStopApprovalInit = h => post('/gxfinan_org_app/gxApplyProgress/stopApprovalInit', h);
  /*5.2.3查看进度-终止流程-保存终止*/
  export const apiStopSH = h => post('/gxfinan_org_app/gxApplyProgress/stopSH', h);
  /*5.2.5查看进度-评价-保存评价*/
  export const apiCommentSave = h => post('/gxfinan_org_app/gxApplyProgress/commentSave', h);
  /*5.3需求详情*/
  export const apiRequirementDetail = h => post('/gxfinan_org_app/gxApplyProgress/requirementDetail', h);
  /*5.4终止受理*/
  export const apiEndFirCheck = h => post('/gxfinan_org_app/gxApplyProgress/endFirCheck', h);
  /*6.1产品收藏（列表）*/
  export const apiCollectList = h => post('/gxfinan_org_app/appGxCreditMng/collectList', h);
  /*7.1银企对话（列表）*/
  export const apiGetDialogueList = h => post('/gxfinan_org_app/bankEnterDialogue/getDialogueList', h);
  /*7.2银企对话 留言回复*/
  export const apiGetDialogueDetail = h => post('/gxfinan_org_app/bankEnterDialogue/getDialogueDetail', h);
  /*7.3银企对话（附件下载）*/
  export const apiDownloadFJ = h => post('/gxfinan_org_app/bankEnterDialogue/downloadFJ', h);
  /*7.4银企对话 保存留言回复*/
  export const apiSaveMessage = h => post('/gxfinan_org_app/bankEnterDialogue/saveMessage', h);
  /*8.1修改密码*/
  export const apiGoEditPw = h => post('/gxfinan_org_app/gxOrg/goEditPw', h);
  /*8.2修改密码-保存*/
  export const apiSaveUserPwd = h => post('/gxfinan_org_app/gxOrg/saveUserPwd', h);
  /*8.3忘记密码页面的发送验证码*/
  export const apiForgetSendVerificationCode = h => post('/gxfinan_org_app/gxOrg/sendVerificationCode', h);
  /*8.4忘记密码-保存*/
  export const apiSaveForgetPwd = h => post('/gxfinan_org_app/gxOrg/saveForgetPwd', h);
  /*9.1查询我的企业基本信息*/
  export const apiGetEnterInfo = h => post('/gxfinan_org_app/userCenter/getEnterInfo', h);
  /*9.2修改我的企业基本信息-保存*/
  export const apiEditEnterInfo = h => post('/gxfinan_org_app/userCenter/editEnterInfo', h);
  /*10.1查询信用授权列表*/
  export const apiBankendtimeList = h => post('/gxfinan_org_app/gxBankendtime/list', h);
  /*10.2新增信用授权信息*/
  export const apiBankendtimeSave = h => post('/gxfinan_org_app/gxBankendtime/save', h);
  /*10.3编辑信用授权信息*/
  export const apiBankendtimeEdit = h => post('/gxfinan_org_app/gxBankendtime/edit', h);
  /*10.4查询线下和贷后授权申请管理列表*/
  export const apiOfflineList = h => post('/gxfinan_org_app/gxBankendtime/offlineList', h);
  /*10.5线下和贷后授权申请保存*/
  export const apiOfflineSave = h => post('/gxfinan_org_app/gxBankendtime/offlineSave', h);
  /*11.1查询新闻动态列表*/
  export const apiNewsList = h => post('/gxfinan_org_app/news/list', h);
  /*11.2查看新闻动态详情*/
  export const apiNewsDetail = h => post('/gxfinan_org_app/news/detail', h);
  /*12.1查询扶贫政策列表*/
  export const apiPolicyList = h => post('/gxfinan_org_app/policy/list', h);
  /*12.2查看扶贫政策详情*/
  export const apiPolicyDetail = h => post('/gxfinan_org_app/policy/detail', h);
  /*13.1查询金融知识列表*/
  export const apiKnowledgeList = h => post('/gxfinan_org_app/knowledge/list', h);
  /*13.2查看金融知识详情*/
  export const apiKnowledgeDetail = h => post('/gxfinan_org_app/knowledge/detail', h);
  /*14.1查询通知公告列表*/
  export const apiNoticeList = h => post('/gxfinan_org_app/notice/list', h);
  /*14.2查看通知公告详情*/
  export const apiNoticeDetail = h => post('/gxfinan_org_app/notice/detail', h);
  /*15 企业认证接口*/
  export const apiGxiAuth = h => post('/gxfinan_org_app/openService/gxiAuth/', h);
  /*15.7 获取线下认证默认支行*/
  export const apiGetOfflineBank = h => post('/gxfinan_org_app/orgauth/getOfflineBank', h);
  /*15.8 线下认证提交*/
  export const apiSaveOfflineAuth = h => post('/gxfinan_org_app/orgauth/saveOfflineAuth', h);
  /*16.1 我的桂信码*/
  export const apiGetGXM = h => post('/gxfinan_org_app/gxm/getGXM', h);
  /*16.2 下载桂信码*/
  export const apiDownloadGXM = h => post('/gxfinan_org_app/gxm/download', h);
  /*17.1 首页统计数据*/
  export const apiIndexData = h => post1('/gxfinan_org_app/indexData/getData', h);
  /*18.1 获取推送消息列表*/
  export const apiGetDatas = h => post('/gxfinan_org_app/pushMessage/getDatas', h);
  /*18.2 获取推送消息详情*/
  export const apiGetPushInfoDetail = h => post('/gxfinan_org_app/pushMessage/getPushInfoDetail', h);
  /*18.4 更新阅读状态*/
  export const apiUpdateReadStatus = h => post('/gxfinan_org_app/pushMessage/updateReadStatus', h);
  /** 
  * json数据拼接方法
  * 
  */
  export const extendJson = (target, source) => {
    for (var obj in source) {
        target[obj] = source[obj];
    }
    return target;
  }
  /*产品类型数据解析替换*/ 
  export const replaceCredit = (str) => {
    if(!str) {
      return;
    }   
    let result = str.replace(/\b(01|02|03|04|05|06|07|99)\b/g, function($1) {
            return {
                "01": "贷款",
                "02": "票据贴现", 
                "03": "贸易融资",
                "04": "银行承兑汇票",
                "05": "信用证", 
                "06": "保函",
                "07": "保理", 
                "99": "其他",
            }[$1];
    });
    return result;
  };
  /*适用对象数据解析替换*/ 
  export const replaceUser = (str) => {       
    if(!str) {
      return;
    }   
    let result = str.replace(/\b(0|1|2|3|4|5|6)\b/g, function($1) {
            return {
                "0": "微型企业",
                "1": "小型企业", 
                "2": "中型企业",
                "3": "大型企业",
                "4": "涉农经营企业", 
                "5": "个体工商户",
                "6": "其他",
            }[$1];
    });
    return result;
  };
  /*担保方式数据解析替换*/ 
  export const replaceGua = (str) => {
    if(!str) {
      return;
    }        
    let result = str.replace(/\b(0|1|2|3|4)\b/g, function($1) {
            return {
                "0": "信用",
                "1": "抵押", 
                "2": "保证",
                "3": "质押",
                "4": "其他",
            }[$1];
    });
    return result;
  };
  /*银行列表数据解析替换*/
  let bankCodeList = {
    GX002: '工商银行',
    GX003: '农业银行',
    GX004: '中国银行',
    GX005: '建设银行',
    GX006: '农发银行',
    GX007: '北部湾银行',
    GX008: '农信联社',
    GX00081: '桂林银行',
    GX009: '邮储银行',
    GX010: '交通银行',
    GX011: '柳州银行',
    GX012: '民生银行',
    GX013: '招商银行',
    GX014: '中信银行',
    GX015: '平安银行',
    GX016: '浦发银行',
    GX017: '进出口银行',
    GX018: '光大银行',
    GX019: '兴业银行',
    GX020: '华夏银行',
    GX021: '国开银行',
    GX023: '汇丰银行',
    GX025: '广发银行',
    GX026: '东亚银行',
    GX027: '渤海银行',
  }
  export const replaceBank = (code) => {
    let bank = bankCodeList[code];
    if(!bank) {
        bank = '村镇银行';
    }
    return bank;
  }
  /*信贷需求审核状态数据解析替换*/ 
  export const replaceLoanStatus = (str) => {
    let status = str;
    switch (str)
        { 
          case '0':status="待受理"; 
          break; 
          case '1':status="受理失败"; 
          break; 
          case '2':status="受理成功,待审核"; 
          break; 
          case '3':status="审核不通过"; 
          break; 
          case '4':status="审核通过，待录入授信合同"; 
          break; 
          case '7':status="已录入授信合同"; 
          break; 
          case '-1':status="已终止信贷交互流程"; 
          break; 
        }
    return status;
  };
  /*企业性质数据解析替换*/ 
  export const replaceEnterProperty = (str) => {
    let x = str;
    switch (str)
        { 
          case '0':x="企业法人"; 
          break; 
          case '1':x="企业非法人"; 
          break; 
          case '2':x="事业单位"; 
          break; 
          case '3':x="个体户"; 
          break; 
          case '4':x="其他"; 
        }
    return x;
  };
  /*所属行业数据解析替换*/ 
  export const replaceIndustry = (str) => {
    let x = str;
    switch (str)
        { 
          case 'A':x="农、林、牧、渔业"; 
          break; 
          case 'B':x="采矿业"; 
          break; 
          case 'C':x="制造业"; 
          break; 
          case 'D':x="电力、燃气及水的生产供应业"; 
          break; 
          case 'E':x="建筑业";
          break; 
          case 'F':x="交通运输、仓储环境和邮政业"; 
          break; 
          case 'G':x="信息传输、计算机服务业"; 
          break;
          case 'H':x="批发和零售业"; 
          break; 
          case 'I':x="住宿和餐饮业"; 
          break; 
          case 'J':x="金融业"; 
          break; 
          case 'K':x="房地产业";
          break; 
          case 'L':x="租赁和商务服务业"; 
          break; 
          case 'M':x="科学研究、技术服务和地质勘查业"; 
          break;
          case 'N':x="水利、环境和公共设施管理"; 
          break; 
          case 'O':x="居民服务和其他服务业"; 
          break; 
          case 'P':x="教育业"; 
          break; 
          case 'Q':x="卫生、社会保障和社会福利业";
          break; 
          case 'R':x="文化、教育和娱乐业"; 
          break; 
          case 'S':x="公共管理和社会组织"; 
          break;
          case 'T':x="其他行业"; 
          break;

        }
    return x;
  };
  /*企业规模解析替换*/ 
  export const replaceScale = (str) => {
    let x = str;
    switch (str)
        { 
          case '0':x="微型"; 
          break; 
          case '1':x="小型"; 
          break; 
          case '2':x="中型"; 
          break; 
          case '3':x="大型"; 
        }
    return x;
  };
  /*证件类型解析替换*/ 
  export const replaceCertificate = (str) => {
    let x = str;
    switch (str)
        { 
          case '0':x="身份证"; 
          break; 
          case '1':x="护照"; 
          break; 
          case '2':x="港澳台居民通行证"; 
          break; 
          case '3':x="港澳台居民居住证";
          break; 
          case '4':x="其他合法身份证件";
        }
    return x;
  };
 